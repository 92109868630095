.profile-card {
    max-width: 29rem;
}

.banner {
    min-height: 8rem;
}

.banner-bg img {
    object-fit: cover;
}

.banner-bg {
    max-height: 8rem;
    min-height: 6rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}