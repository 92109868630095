.drop__area {
    position: relative;
    height: "max-content";

    .overlay__container {
        position: absolute;
        inset: 0px;
        z-index: 10;

        .overlay__blur {
            position: absolute;
            inset: 0px;
            opacity: 0.85;
            background: white;

            &--hovering {
                border: 2px dashed #556ee6;
            }
        }

        .overlay__main {
            position: absolute;
            inset: 0px;
            z-index: 10;

            border: 2px dashed #c5ccea;
            border-radius: 4px;

            transition: all 0.2s ease-in-out;

            &--hovering {
                border: 2px dashed #556ee6;

                -moz-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
                -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
                box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
            }
        }

        .overlay__content {
            position: absolute;
            z-index: 5;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}