.skeleton {
    --skeleton-bg-color: #ededed;

    &.image {
        background-color: var(--skeleton-bg-color);
        width: 100%;
        &.animation {
            background: linear-gradient(100deg,
                    rgba(255, 255, 255, 0) 40%,
                    rgba(255, 255, 255, .5) 50%,
                    rgba(255, 255, 255, 0) 60%) var(--skeleton-bg-color);
            background-size: 200% 100%;
            background-position-x: 180%;
            animation: 1s skeleton-loading ease-in-out infinite;
        }
    }
}

@keyframes skeleton-loading {
    to {
        background-position-x: -20%;
    }
}