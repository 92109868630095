.tile {
  width: 100%;
  height: 100%;
  margin-right: 0.5rem;
  padding: 0rem !important;
  overflow: hidden;
}

.tile:last-child {
  margin-right: 0 !important;
}

.tile.main video {
  object-fit: contain;
}

.tile video {
  object-fit: cover;
}

.tile.local video {
  transform: scale(-1, 1);
  /* rotate video */
}

.tile .bg-secondary.bg-soft {
  z-index: 5;
}

.tile .overlay-user {
  height: 4rem;
  width: 4rem;
}

.tile .overlay-user-main {
  height: 6rem;
  width: 6rem;
}