html,
body,
#root,
#layout-wrapper,
.main-content {
  height: 100%;
  min-height: 100%;
}


$action-menu-height: 52.5px;
$footer-height: 60px;
$navbar-height: 60px;

$participant-grid-mobile: '10vh';
$participant-grid: '20vh';
$core-content-height: calc(100vh - (#{$navbar-height} + 16px + 3px + #{$footer-height} + #{$participant-grid} + #{$action-menu-height}));

$sidebar-look-tittle: 42px;
$sidebar-card-footer: 60px;

$sidebar-cardbody-height: calc(100% - (#{$sidebar-card-footer} + #{$sidebar-look-tittle}));

.participant-grid {
  height: #{$participant-grid};
}

.sidebar-look-cardbody {
  height: $sidebar-cardbody-height;
}

.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.text-primary {
  color: #556ee6 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}


.windows-scrollbar {
  /* Firefox scrollbar */
  scrollbar-width: thin;

  /* Chrome scrollbar */
  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #878787;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-corner {}

  ::-webkit-resizer {}
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: thin;
  /* Firefox 64 */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

body[data-layout="horizontal"] .page-content {
  padding: calc(55px + 24px / 3) calc(24px / 2) 60px calc(24px / 2);
}

.collapse-button {
  display: block;
  height: 7.5rem;
  width: 1.75rem;
  border: unset;
}

.active-look-product {
  z-index: 2000 !important;
}

.collapse-button.left {
  margin-left: -2.5rem;
}

.collapse-button.right {
  margin-right: -2.5rem;
}

.container-xl {
  width: 95%;
}

.spinner-lg {
  width: 5rem !important;
  height: 5rem !important;
}

.spinner-md {
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.spinner-sm {
  width: 2rem !important;
  height: 2rem !important;
}

.product-breadcrumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10rem;
}

.product-card-description {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-card-image {
  height: 10rem;
  width: 100%;
  object-fit: contain;
}

.core-content {
  height: $core-content-height;
}

.empty-radio {
  position: relative;
  opacity: 0.75;

  &.form-check-input:checked[type="radio"] {
    background-image: unset !important;
  }

  &:disabled {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.6 !important;
      border-radius: 50%;
      z-index: -1;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      width: 100%;
      height: 100%;
      border-bottom: 2px solid black;
      transform: rotate(45deg) translateX(0px) translateY(-7px);
      z-index: 1;
    }
  }
}


// TODO: conflict with configurator 
.container-sm {
  --bs-gutter-x: unset;
  --bs-gutter-y: unset;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.screen-waiting {
  background-color: lightgray;
}


.joined-mobile-screen {
  height: 70%;
}

.joined-mobile-bottom {
  height: 30%;
}

.action-menu {
  height: $action-menu-height;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.hw-0 {
  width: 0px;
  height: 0px;
}

@media only screen and (max-width: 1367px) {
  .container-xl {
    width: 95% !important;
    max-width: 95% !important;
  }
}

@media only screen and (max-width: 570px) {

  .participant-grid {
    height: #{$participant-grid-mobile};
  }

  .page-content {
    height: 100%;
  }
}