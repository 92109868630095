$nav-tab-height: 34.5px;

.sidebar-raise {
  margin-top: -2.15rem !important;
}


.sidebar-list-container {
  height: 100%;
  overflow-y: auto;
}

tbody tr:last-child td {
  border: none;
}

.bg-muted {
  background-color: #74788d !important;
}

.sidebar-product-img {
  height: 4.5rem;
  width: 4.5rem;
  object-fit: contain;
}