.product-preview {
	width: 100%;
	object-fit: contain;
	max-height: 300px;
	max-width: 300px;
}

@media only screen and (max-width: 1281px) {
	.product-preview {
		max-width: 250px;
		max-height: 250px;
	}
}