.pulse-danger {
  animation: pulse-danger 2s infinite;
}

.pulse-primary {
  animation: pulse-primary 2s infinite;
}

.screen-loading {
  background: linear-gradient(225deg, #1987a5, #0f5569, #0c3a47);
  background-size: 600% 600%;
  -webkit-animation: bg-animation 6s ease infinite;
  -moz-animation: bg-animation 6s ease infinite;
  animation: bg-animation 6s ease infinite;
}

@keyframes pulse-danger {
  0% {
    box-shadow: 0 0 0 0 rgba(120, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(146, 66, 66, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(146, 66, 66, 0);
  }
}

@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgba(85, 110, 230, 0.7);
    ;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(146, 66, 66, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(146, 66, 66, 0);
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 0% 86%
  }

  50% {
    background-position: 100% 15%
  }

  100% {
    background-position: 0% 86%
  }
}

@-moz-keyframes bg-animation {
  0% {
    background-position: 0% 86%
  }

  50% {
    background-position: 100% 15%
  }

  100% {
    background-position: 0% 86%
  }
}

@keyframes bg-animation {
  0% {
    background-position: 0% 86%
  }

  50% {
    background-position: 100% 15%
  }

  100% {
    background-position: 0% 86%
  }
}