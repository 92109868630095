.price-filter-slider {
  width: 100%;
  height: 1.5rem;

  .price-filter-track {
    position: relative;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    // box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
    height: 16px;

    &.price-filter-track-0 {
      background: white;
      height: 16px;
    }

    &.price-filter-track-1 {
      background: #3fb8af;
      height: 16px;
    }

    &.price-filter-track-2 {
      background: white;
      height: 16px;
    }
  }

  .price-filter-thumb {
    font-size: 0.8rem;
    box-sizing: border-box;

    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    cursor: pointer;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    width: 34px;
    height: 28px;
    top: -6px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 14px;
      width: 1px;
      background: #e8e7e6;
      left: 14px;
      top: 6px;
    }

    &::after {
      left: 17px;
    }
  }
}

.price-filter-tooltip {
  display: none;
}

.price-filter-thumb:active .price-filter-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.price-filter-thumb:focus-visible {
  outline: none !important;
}