.chat {
  z-index: 9999;
  width: 28.125rem;
  background-color: #fff;
  padding: 0.25rem;
  position: absolute;
  right: 1.5rem;
  bottom: 6.725rem;
  border-radius: 0.25rem;
}

li:hover {
  background-color: unset;
}

.chat-conversation {
  height: 21.875rem;
  overflow-y: scroll;
}

.conversation-list {
  margin-bottom: 0.25rem !important;
  max-width: 80%;
}

.message-item {
  word-wrap: break-word;
  margin-bottom: 0px;
}

.chat-input {
  padding-right: 0.75rem !important; // change bootstrap class padding-right: 120px
  background-color: rgba(85, 110, 230, .1);
}


@media only screen and (max-width: 570px) {
  .chat {
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    border-radius: unset;
  }

  .chat-conversation {
    height: 70vh;
    overflow-y: scroll;
    touch-action: auto;
  }

  .chat-input-section {
    height: 25vh;
    font-size: 1rem;
  }
}