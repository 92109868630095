.primary-category {
  margin-top: 0.75rem;
  max-height: 25rem;
  overflow-y: hidden;
}

.secondary-category {

  &>li {
    margin-bottom: 0.75rem;
    padding-left: 0.25rem;
  }

  &>li:first-child {
    margin-top: 1rem;
  }

  &>li:last-child {
    margin-bottom: 0rem;
  }
}